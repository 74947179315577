<template>
    <div>
        <h3 class="t-title">
            {{ $t('title') }}
        </h3>

        <div class="chart-wrapper">
            <div class="l-padded datepicker">
                <DateRangeInput v-model="customRange" />
            </div>

            <div class="chart">
                <AssetChartsSettings
                    class="chart__settings"
                    :axes="settingsAxes"
                    @change="handleSettingsChange"
                />

                <p class="l-padded-x form-label">
                    {{ $t('hint') }}
                </p>

                <div class="l-stack l-gap-1 filter">
                    <BaseMultiselect
                        v-model="filterAssets"
                        :placeholder="$t('shared.assets')"
                        :options="trackers"
                        :custom-label="
                            option =>
                                `${option.asset_details.name} (${option.deveui})`
                        "
                        track-by="id"
                        multiple
                        :max="maxFilterAssets"
                    />

                    <MeasurementSelect
                        v-model="filterMeasurements"
                        :placeholder="$t('measurements')"
                        :options="measurementOptions"
                        multiple
                        :group-select="filterAssets.length <= 1"
                        :max="maxFilterMeasurements"
                        taggable
                    />
                </div>

                <div v-if="isLoading" class="l-stack l-center l-padded">
                    <VSpinner size="medium" line-fg-color="#000" :speed="1" />
                </div>

                <ApexChart
                    v-else
                    ref="chart"
                    :options="chartOptionsComputed"
                    :series="series"
                    :height="chartHeight"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce, flatten } from 'lodash'
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, measurementHelper } from '@/utils'
import AssetChartsSettings from '@/components/AssetChartsSettings'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import MeasurementSelect from '@/components/redesigned/MeasurementSelect'
import UrlHelper from '@/mixins/UrlHelper'

export default {
    name: 'DashboardGenericChartView',
    components: {
        ApexChart,
        AssetChartsSettings,
        BaseMultiselect,
        DateRangeInput,
        MeasurementSelect,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    data() {
        return {
            responseData: [],
            filterAssets: [],
            filterMeasurements: [],
            series: [],
            chartOptions: {
                chart: {
                    stacked: false,
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                    style: 'full',
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
            measurementOptions: [
                ...measurementHelper.measurements,
                ...measurementHelper.measurementsAnalogChannels,
                ...measurementHelper.measurementsSalt,
                ...measurementHelper.measurementsState,
                ...measurementHelper.measurementsTemperature,
                ...measurementHelper.measurementsVoltage,
            ],
            yAxisBounds: [],
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        chartHeight() {
            return document.documentElement.clientHeight - 380
        },
        chartOptionsComputed() {
            const yaxis = this.isMultiAxis
                ? this.series.map(({ name }, index) => {
                      const measurementIndex =
                          index % this.filterMeasurements.length
                      const measurement = this.filterMeasurements[
                          measurementIndex
                      ]
                      const [min, max] = this.yAxisBounds[measurementIndex]
                          ? this.yAxisBounds[measurementIndex]
                          : [
                                this.getYMinValue(measurement),
                                this.getYMaxValue(measurement),
                            ]

                      return index > measurementIndex
                          ? {
                                show: false,
                                seriesName: this.series[measurementIndex].name,
                                min,
                                max,
                            }
                          : {
                                seriesName: name,
                                min,
                                max,
                                decimalsInFloat: 2,
                                title: {
                                    text: this.getMeasurementLabel(measurement),
                                },
                                opposite: index % 2 === 0,
                            }
                  })
                : this.filterMeasurements.map((measurement, index) => ({
                      seriesName: measurement,
                      ...(this.yAxisBounds[index]
                          ? {
                                min: this.yAxisBounds[index][0],
                                max: this.yAxisBounds[index][1],
                            }
                          : {
                                min: this.getYMinValue(measurement),
                                max: this.getYMaxValue(measurement),
                            }),
                      decimalsInFloat: 2,
                      title: {
                          text: this.getMeasurementLabel(measurement),
                      },
                      opposite: index % 2 === 0,
                  }))

            return {
                ...this.chartOptions,
                stroke: {
                    curve: this.curveTypes,
                    width: 3,
                },
                yaxis,
            }
        },
        curveTypes() {
            if (this.isMultiAxis) {
                const types = this.filterMeasurements.map(this.getCurveType)
                return flatten(Array(this.filterAssets.length).fill(types))
            }

            return this.filterAssets.length === 1
                ? this.filterMeasurements.map(this.getCurveType)
                : Array(this.filterAssets.length).fill(
                      this.getCurveType(this.filterMeasurements[0])
                  )
        },
        isMultiAxis() {
            return (
                this.filterAssets.length === 2 &&
                this.filterMeasurements.length === 2
            )
        },
        maxFilterAssets() {
            return this.filterMeasurements.length < 2
                ? undefined
                : this.filterMeasurements.length > 2
                ? 1
                : 2
        },
        maxFilterMeasurements() {
            return this.filterAssets.length < 2
                ? undefined
                : this.filterAssets.length > 2
                ? 1
                : 2
        },
        settingsAxes() {
            return this.filterMeasurements.map(this.getMeasurementLabel)
        },
    },
    watch: {
        customRange() {
            this.load()
        },
        filterAssets() {
            this.load()
        },
        filterMeasurements() {
            this.yAxisBounds = Array(this.filterMeasurements.length).fill()
            this.renderData()
        },
    },
    created() {
        moment.locale(this.$i18n.locale)
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
            filterAssets: {
                key: 'asset',
                type: 'array',
                serialize: value => value.map(({ asset }) => asset),
                deserialize: value =>
                    value
                        .map(item =>
                            this.trackers.find(({ asset }) => asset === +item)
                        )
                        .filter(Boolean)
                        .slice(0, this.maxFilterAssets),
            },
            filterMeasurements: {
                key: 'measurement',
                type: 'array',
                deserialize: value =>
                    value.slice(0, this.maxFilterMeasurements),
            },
        })
    },
    methods: {
        getCurveType(measurement) {
            return /^d\d+$/.test(measurement) ? 'stepline' : 'straight'
        },
        getMeasurementLabel(measurement) {
            return this.$root.$te(`shared.measurements.${measurement}`)
                ? this.$t(`shared.measurements.${measurement}`)
                : measurement
        },
        handleSettingsChange(...args) {
            this.yAxisBounds = (Array.isArray(args[0]) ? args[0] : [args]).map(
                ([mode, min, max]) => {
                    switch (mode) {
                        case 'auto':
                        case 'custom':
                            return [min, max]
                    }
                }
            )
        },
        load: debounce(async function() {
            this.isLoading = true
            await this.loadData()
            this.renderData()
            this.isLoading = false
        }),
        async loadData() {
            const startDate = encodeURIComponent(
                moment(this.customRange.startDate).format()
            )
            const endDate = encodeURIComponent(
                moment(this.customRange.endDate).format()
            )

            this.responseData = await Promise.all(
                this.filterAssets.map(async ({ id }) => {
                    let results = []

                    let url =
                        'measurements/?' +
                        `tracker=${id}` +
                        '&fields=timestamp,sensor_data' +
                        `&timestamp_min=${startDate}` +
                        `&timestamp_max=${endDate}` +
                        `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

                    while (url) {
                        const { data } = await httpHelper.get(url)
                        results = results.concat(data.results)
                        url = data.next
                    }

                    return results
                })
            )
        },
        renderData() {
            if (!this.responseData.length) {
                this.series = []
                return
            }
            const series = []
            this.filterAssets.forEach(
                (asset, i) =>
                    this.filterMeasurements.map(measurement => {
                        const assetName = asset.asset_details.name
                        const name = this.isMultiAxis
                            ? `${assetName} (${this.getMeasurementLabel(
                                  measurement
                              )})`
                            : this.filterAssets.length === 1
                            ? this.getMeasurementLabel(measurement)
                            : assetName

                        if (measurement === 'humidity_absolute') {
                            series.push({
                                key: measurement,
                                name,
                                data: this.responseData[i]
                                    .filter(
                                        item =>
                                            Object.prototype.hasOwnProperty.call(
                                                item.sensor_data,
                                                'humidity'
                                            ) &&
                                            Object.prototype.hasOwnProperty.call(
                                                item.sensor_data,
                                                'temperature'
                                            )
                                    )
                                    .map(item => [
                                        item.timestamp,
                                        measurementHelper.calculateAbsoluteHumidity(
                                            item.sensor_data.humidity,
                                            item.sensor_data.temperature
                                        ),
                                    ]),
                            })
                            return
                        }

                        let converter =
                            measurementHelper.converters[measurement] ||
                            measurementHelper.converters.default
                        const data = this.responseData[i].filter(item =>
                            Object.prototype.hasOwnProperty.call(
                                item.sensor_data,
                                measurement
                            )
                        )

                        if (measurement === 'distance') {
                            switch (asset.asset_details.asset_type_type) {
                                case 'bin':
                                    converter =
                                        measurementHelper.convertToBinLevel
                                    break
                                case 'sbb-bin':
                                    converter =
                                        measurementHelper.convertToSbbBinLevel
                                    break
                            }
                        }

                        series.push({
                            key: measurement,
                            name,
                            data: data.map(item => [
                                item.timestamp,
                                converter(item.sensor_data[measurement]),
                            ]),
                        })
                    }),
                []
            )
            this.series = series
        },
    },
}
</script>

<i18n>
{
    "en": {
        "hint": "Select either one asset and multiple measurements, or multiple assets and one measurement:",
        "measurements": "Measurements",
        "title": "Data insights"
    },
    "de": {
        "hint": "Wählen Sie entweder ein Asset und mehrere Messwerte, oder mehrere Assets und einen Messwert aus:",
        "measurements": "Messungen",
        "title": "Datenanalyse"
    },
    "fr": {
        "hint": "Sélectionnez soit un asset et plusieurs mesures, soit plusieurs assets et une mesure :",
        "measurements": "Mesures",
        "title": "Analyse des données"
    },
    "it": {
        "hint": "Selezionare un asset e più misurazioni, oppure più asset e una misurazione.:",
        "measurements": "Misure",
        "title": "Grafico generico"
    }
}
</i18n>

<style lang="scss" scoped>
.t-title {
    margin: 1rem 1rem 0;
}

.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;

    &__settings {
        padding: 0 0 1rem 1rem;
    }
}

.filter {
    margin-bottom: 1rem;
    margin-left: 1rem;

    button {
        max-width: 120px;
    }
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

@include respond-to('for-tablet-down') {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        width: auto;
        border-bottom: $style-border;
    }

    .chart {
        border: none;
    }
}
</style>
